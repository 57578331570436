<template>
  <v-dialog
    width="700"
    persistent
    transition="slide-x-transition"
    v-model="getModalViewAddress"
  >
    <v-card color="#333" dark class="expande-horizontal wrap">
      <div class="expande-horizontal px-6 py-3">
        <v-btn dark @click="closeModalViewAddress" icon>
          <v-icon color="grey">
            mdi-chevron-left
          </v-icon>
        </v-btn>
        <span style="font-size: 17pt;" class="fonte grey--text">
          Endereço
        </span>
      </div>

      <div class="expande-horizontal wrap py-6 pb-0">
        <v-flex xs12>
          <v-form ref="address_form">
            <v-flex class="px-7" xs12>
                <div class="expande-horizontal">
                    <v-text-field
                        v-model="getAddress.postal_code"
                        autofocus
                        @keyup.enter="getCep(getAddress.postal_code)"
                        dense
                        label="CEP"
                    ></v-text-field>
                    <v-btn text :color="$theme.primary" small @click="getCep(getAddress.postal_code)">Buscar CEP</v-btn>
                </div>
            </v-flex>
            
            <v-flex class="px-7" xs12>
              <span class="py-3 fonte orange--text">
                Endereço
              </span>
              <v-text-field
                v-model="getAddress.address"
                dense
                solo
                label="Endereço"
              ></v-text-field>
            </v-flex>
            
            <v-flex class="px-7" xs12>
              <span class="py-3 fonte orange--text">
                Bairro
              </span>
              <v-text-field
                v-model="getAddress.neigthborhood"
                dense
                solo
                label="Bairro"
              ></v-text-field>
            </v-flex>
            
            <v-flex class="px-7" xs12>
              <span class="py-3 fonte orange--text">
                Cidade
              </span>
              <v-text-field
                v-model="getAddress.city"
                dense
                solo
                label="Cidade"
              ></v-text-field>
            </v-flex>
            
            <v-flex class="px-7" xs12>
              <span class="py-3 fonte orange--text">
                Estado
              </span>
              <v-text-field
                v-model="getAddress.uf"
                dense
                solo
                label="Estado"
              ></v-text-field>
            </v-flex>
            
            <v-flex class="px-7" xs12>
              <span class="py-3 fonte orange--text">
                Número da casa
              </span>
              <v-text-field
                v-model="getAddress.house_number"
                :rules="[v => !!v || 'Preencha o número da casa']"
                dense
                solo
                label="Número da casa"
              ></v-text-field>
            </v-flex>
            
            <v-flex class="px-7" xs12>
              <v-switch
                :color="$theme.primary"
                v-model="getAddress.preferential"
                dense
                solo
                label="Definir como endereço padrão?"
              ></v-switch>
            </v-flex>
          </v-form>
        </v-flex>
      </div>

      <div class="expande-horizontal">
        <v-btn large @click="validate" tile block color="green">
          <span class="fonte"> Salvar Endereço </span>
        </v-btn>
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
export default {
  computed: {
    ...mapGetters(["getModalViewAddress", "getAddress"])
  },
  methods: {
    ...mapActions(["createAddress", "closeModalViewAddress", "getCep"]),
    validate() {
      if (this.$refs.address_form.validate()) {
        this.createAddress(this.getAddress);
      }
    }
  }
};
</script>
